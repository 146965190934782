<template>
  <div :style="{ maxHeight: elHeight, overflow: 'auto' }" @scroll.self="searchNextList">
      <hb-data-table
        :headers="headers"
        :items="getSettingsData[level]"
        @click:row="editItem"
      >
          <template v-slot:item.name="{ item }">{{item.name}}</template>
          <template v-slot:item.type="{ item }"><span class="text-capitalize">{{ getDocumentTypeName(item.type)  }}</span></template>
          <template v-slot:item.properties="{ item }">
            <hb-tooltip dashed>
              <template v-slot:item>
                {{ item.Properties.length }} Properties
              </template>
              <template v-slot:body>
                <span v-if="item.Properties.length > 0">
                  <v-row no-gutters v-for="(property, i) in filteredProperties(item.Properties)" :key="i">
                      <span class="truncate" v-if="property">
                        <span class="font-weight-medium">{{property.number}}</span> - {{property.name}}
                      </span>
                  </v-row>
                  <v-row no-gutters v-if="item.Properties.length > 15">...</v-row>
                </span>
                <span v-else>
                  No Properties Assigned
                </span>
              </template>
            </hb-tooltip>
          </template>
          <template v-slot:item.created_on="{ item }">{{ item.created_on | formatDateServices}}</template>
          <template v-slot:item.modified_on="{ item }">{{ item.modified_on | formatDateServices }}</template>
          <template v-slot:item.actions="{ item }">
              <hb-menu options>
                <v-list>
                  <v-list-item class="list-item-height" @click="downloadItem(item)">
                      <v-list-item-title>Download</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="list-item-height" @click="$emit('editItem', item)" v-if="!isPropertyLevel">
                      <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="list-item-height" @click="confirmDelete(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </hb-menu>
          </template>
      </hb-data-table>
      <v-row class="justify-center ma-0 pa-0" v-if="allowScrollRequest && loadingDocumentTemplates">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-row>
      <active-processes-modal v-if="isDeleteItem" v-model="isDeleteItem" :activeProcesses="activeProcesses" :allowUpdate="allowUpdate" :isLoaded="isLoaded" @deleteItem="deleteItem" @closeModal="closeModal" action="delete"></active-processes-modal>
  </div>
</template>

<script type="text/babel">
import ActiveProcessesModal from './ActiveProcessesModal.vue';
import api from '../../../assets/api.js';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import { notificationMixin } from '@/mixins/notificationMixin.js';
export default {
  name: "DocumentLibrary",
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "# of Properties", value: "properties", sortable: false },
        { text: "Created", value: "created_on" },
        { text: "Last Modified", value: "modified_on" },
        { text: "", value: "actions", align: "right", sortable: false, width: 10 }
      ],
      loadingDocuments: false,
      allowScrollRequest: true,
      loadingDocumentTemplates: false,
      isDeleteItem: false,
      selectedItem: {},
      activeProcesses: [],
      isLoaded: false,
      allowUpdate: false,
      offset: 2
    };
  },
  mixins: [notificationMixin],
  props: ['isPropertyLevel', 'property', 'level'],
  components: {
    ActiveProcessesModal
  },
  created() {
    if(this.isPropertyLevel) {
      this.headers = [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Generated", value: "created_on" },
        { text: "Last Modified", value: "modified_on" },
        { text: "", value: "actions", align: "right", sortable: false, width: 10 }
      ];
    }
  },
  computed: {
    ...mapGetters({
      getUnSignedDocumentTypes: 'documentsStore/getUnSignedDocumentTypes',
      getSettingsData: 'documentsStore/getSettingsData',
    }),
    elHeight(){
      let pHeight  = document.documentElement.clientHeight;

      if(this.isPropertyLevel){
        var elementHeight = 57;
        if(pHeight < 768) {
          elementHeight = 52;
        } else if(pHeight >= 1050) {
          elementHeight = 67;
        } else if(pHeight >= 860) {
          elementHeight = 62;
        }
      } else {
        var elementHeight = 66;
        if(pHeight < 768) {
          elementHeight = 61;
        } else if(pHeight >= 1050) {
          elementHeight = 76;
        } else if(pHeight >= 860) {
          elementHeight = 71;
        }
      }

      return `${elementHeight}vh`;
    }
  },
  methods: {
    ...mapActions({
      downloadDocument: 'documentsStore/downloadDocument',
      fetchDocuments: 'documentsStore/fetchDocuments',
      fetchSettingsData: 'documentsStore/fetchSettingsData',
    }),
    ...mapMutations({
      setDocuments: 'documentsStore/setDocuments',
    }),
    closeModal() {
      this.activeProcesses = [];
      this.isDeleteItem = false;
      this.$emit('close');
    },
    getDocumentTypeName(type) {
      return this.getUnSignedDocumentTypes.length && this.getUnSignedDocumentTypes.find(t => t.value == type) ? this.getUnSignedDocumentTypes.find(item => item.value == type).name : type;
    },
    searchNextList({ target: { scrollTop, clientHeight, scrollHeight }}){
      if ((Math.ceil(scrollTop) + clientHeight >= scrollHeight) && !this.loadingDocumentTemplates) {
          if (this.allowScrollRequest) {
            this.loadingDocumentTemplates = true;
            this.fetchDocumentsList();
          }
      }
    },

    async fetchDocumentsList(){
      let limit = 20;
      if(this.getSettingsData[this.level].length % limit !== 0){
        this.allowScrollRequest = false;
        this.loadingDocumentTemplates = false;
        return
      }
      // let offset = 1
      // if(this.getSettingsData[this.level]) {
      //   offset = this.getSettingsData[this.level].length == 0 ? 1 : Math.ceil(this.getSettingsData[this.level].length / limit) + 1
      // }
      let path = (this.isPropertyLevel) ? `&property_ids[]=${this.property.id}` : '';
      let data = {
        component: this,
        params: api.DOCUMENT + `?limit=${limit}&offset=${this.offset}&document_type=un-signed` + path,
        level: this.level
      }
      try {
        const documents = await this.fetchSettingsData(data);
        if(documents.length == 0) {
          this.allowScrollRequest = false;
        } else {
          this.allowScrollRequest =  true;
          this.offset++
        }
      } catch(err) {
        this.allowScrollRequest = false;
        this.showMessageNotification({ description: err });
      } finally {
        this.loadingDocumentTemplates = false;
      }
    },
    async downloadItem(item) {
      const url = `${item.id}/download?key=${item.key}`;
      const downloadedFile = await this.downloadDocument(url);
      var buffer = Buffer.from(downloadedFile.Body.data);
      var blob = new Blob([buffer], { type: '.docx' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = item.name + '.docx';
      link.click();
    },
    filteredProperties(properties) {
      return (properties.length > 15) ? properties.slice(0, 15) : properties;
    },
    async confirmDelete(item) {
      let path = (this.isPropertyLevel) ? `?property_ids[]=${this.property.id}` : '?is_corporate=true';
      await api.get(this, api.DOCUMENT + item.id + '/active-processes' + path).then(payload => {
        if(payload.active_processes.length) {
          this.activeProcesses = payload.active_processes;
        }
        this.allowUpdate = payload.allow_update;
        this.isDeleteItem = true;
        this.selectedItem = item;
      }).catch(err => {
        this.showMessageNotification({description: err});
      });
    },
    async deleteItem() {
      this.isLoaded = true;
      let path = (this.isPropertyLevel) ? api.PROPERTIES + this.property.id + `/documents/` : api.DOCUMENT;
      await api.delete(this, path + this.selectedItem.id).then(async payload => {
        this.isLoaded = false;
        if(payload.document_id) {
          this.setDocuments([]);
          this.$emit('onDocumentsUpdated');
          this.showMessageNotification({type: 'success', description: "You deleted the selected document."});
        }
      }).catch(err => {
        this.showMessageNotification({description: err});
      });
      this.isDeleteItem = false;
    },
    editItem(item) {
      if (!this.isPropertyLevel) {
        this.$emit("editItem", item);
      }
    },
  },
  watch: {
    "isPropertyLevel"() {
      this.headers = [
        { text: "Document Name", value: "name",  },
        { text: "Type", value: "type" },
        { text: "# of Properties", value: "properties" },
        { text: "Created", value: "created_on" },
        { text: "Last Modified", value: "modified_on" },
        { text: "", value: "actions", align: "right", sortable: false, width: 10 }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item-height {
    min-height: 40px !important;
    min-width: 156px;
}
.truncate {
  display: inline-block;
  vertical-align:middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.border-bottom-dashed {
  border-bottom: 1px dashed;
}
</style>